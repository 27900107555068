<template>
  <div class="container">
    <h4 class="text-center my-4">Индивидуальная нагрузка преподавателя</h4>

    <PrimePreloader v-if="loading"/>
    <div v-else class="my-4">

      <DataTable :value="ppsLoad_form.teacherLoadCalculation" :paginator="true" :rows="10" showGridlines
                 paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                 :rowsPerPageOptions="[10,20,50]"
                 currentPageReportTemplate="Показано {last} из {totalRecords}"
                 stripedRows responsiveLayout="scroll">


        <Column field="discipline_name" header="Название дисциплины"></Column>

        <Column header="ОП" style="width: 25%">
          <template #body="{data}">
            <div v-if="data.education_programs.length == 1">
              {{ data.education_programs[0].education_speciality_code }}
              {{ data.education_programs[0].education_speciality_name }} -
              {{ data.education_programs[0].language }}
            </div>
            <div v-else>
              <p>
                <a data-bs-toggle="collapse"
                   :href="'#collapseEpd'+data.education_discipline_id + data.semester + data.credit"
                   aria-expanded="false"
                   :aria-controls="'collapseEpd'+data.education_discipline_id + data.semester + data.credit">
                  Список ОП
                </a>
              </p>
              <div class="collapse" :id="'collapseEpd'+data.education_discipline_id + data.semester + data.credit">
                <div v-for="(item, index) in data.education_programs" :key="index">
                  {{ item.education_speciality_code }} {{ item.education_speciality_name }} -
                  {{ item.language }}
                  <br>
                  <br>
                </div>
              </div>
            </div>
          </template>
        </Column>
        <Column field="credit" header="Кредит"></Column>
        <Column field="semester" header="Семестр"></Column>
        <Column field="total_students_count" header="Количество студентов"></Column>
        <Column field="lecture_count" header="Количество лекций"></Column>
        <Column field="seminar_count" header="Количество семинаров"></Column>
        <Column field="lecture_hour" header="Лекция"></Column>
        <Column field="seminar_hour" header="Семинар"></Column>
        <Column field="office_hour" header="Офисные часы"></Column>

      </DataTable>

      <div v-if="ppsLoad_form.teacherLoadCalculation.length" class="my-4 text-center">
        <Button label="Согласен с нагрузкой" @click="openConfirmLoad"/>
      </div>


      <!-- action dialog -->
      <Dialog header="Подтверждение"
              :visible="displayConfirmLoad" :closable="false" :modal="true"
              :style="{width: '100%', maxWidth: '400px'}">
        <div class="mt-2">
          Согласны с нагрузкой?
        </div>
        <template #footer>
          <Button icon="pi pi-times" label="Заркыть" class="p-button-text" @click="closeConfirmLoad"/>
          <Button icon="pi pi-check" label="Согласен" :loading="loadingConfirmLoad" @click="confirmLoad" autofocus/>
        </template>
      </Dialog>
      <!-- end action dialog -->

    </div>
  </div>
</template>


<script>

  import {mapGetters, mapActions, mapMutations, mapState} from "vuex";

  export default {
    name: "TeacherLoadCalculation",
    data() {
      return {
        loading: true,
        displayConfirmLoad: false,
        loadingConfirmLoad: false,
      }
    },
    computed: {
      ...mapState('ppsLoad', ['ppsLoad_form']),

    },

    methods: {
      ...mapActions('ppsLoad', ['GET_TEACHER_LOAD_CALCULATION', 'POST_CONFIRM_PPS_LOAD']),
      openConfirmLoad() {
        this.displayConfirmLoad = true
      },
      closeConfirmLoad() {
        this.displayConfirmLoad = false
      },
      async confirmLoad() {
        this.loadingConfirmLoad = true
        const res = await this.POST_CONFIRM_PPS_LOAD()
        if (res) {
          this.closeConfirmLoad()
        }
        this.loadingConfirmLoad = false
      }
    },

    async mounted() {
      await this.GET_TEACHER_LOAD_CALCULATION()
      this.loading = false
    }
  }
</script>

<style scoped>
</style>